import { AddressableItem } from "../../common/addressable-item";

export class PracticeBase extends AddressableItem {
  public id: string;
  public currency: string;
  public currency_code: string;
  public dentally_practice_id: number;
  public dial_code: string;
  public is_multisite: boolean;
  public iso_country_code: string;
  public last_refresh_time: Date | null;
  public last_complete_sync_time: Date | null;
  public logo_url: string;
  public medical_history_expiry_days: number;
  public name: string;
  public nhs: boolean;
  public nhs_country: string;
  public nhs_payment_plan_id: string;
  public opening_hours: string;
  public patient_email_address: string;
  public phone_number: string;
  public portal_online_signing_price: number | null;
  public portal_online_booking_price: number | null;
  public refresh_status: string | null;
  public restricted: boolean;
  public requires_migration: boolean;
  public last_booked_appointments_sync_time: Date | null;
  public show_acquisition_source: boolean;
  public show_insurance: boolean;
  public show_emergency_contact: boolean;
  public show_patient_gp_details: boolean;
  public slug: string;
  public time_zone: string;
  public website: string;
  public pms_access_token?: string;
}
