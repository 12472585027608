<div class="flex flex-row" *ngIf="devService.shouldShowSparkles">
  <div
    class="bg-ruby-500 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-opacity-10 text-white hover:bg-opacity-100 hover:text-white"
    (click)="showObjectDetails($event)"
  >
    <ng-icon name="heroSparkles" size="20"></ng-icon>
  </div>
  <!--TEMP UNTIL WE ADD UI FOR AUDITING-->
  <div
    *ngIf="show_history"
    class="bg-ruby-500 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-opacity-10 text-white hover:bg-opacity-100 hover:text-white"
    (click)="showObjectHistory($event)"
  >
    <ng-icon name="heroClock" size="20"></ng-icon>
  </div>
  <!--TEMP UNTIL WE ADD UI FOR AUDITING-->
</div>
