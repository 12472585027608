import { trigger, style, transition, animate, keyframes, query, stagger } from '@angular/animations';

const cubic_bezier = 'cubic-bezier(0.645, 0.045, 0.355, 1.000)';

export const ACCORDION_ANIMATION = trigger('accordionAnimation', [
  transition(':enter', [style({ opacity: 0, height: 0 }), animate(`150ms 0ms ${cubic_bezier}`, style({ opacity: 1, height: '*' }))]),
  transition(':leave', [animate(`150ms 0ms ${cubic_bezier}`, style({ opacity: 0, height: 0 }))]),
]);

export const TOAST_TOP_ANIMATION = trigger('toastTopAnimation', [
  transition(':enter', [style({ transform: 'translateY(-100%)' }), animate(`300ms 0ms ${cubic_bezier}`, style({ transform: 'translateY(0%)' }))]),
  transition(':leave', [animate(`300ms 0ms ${cubic_bezier}`, style({ transform: 'translateY(-100%)' }))]),
]);

export const TOAST_BOTTOM_ANIMATION = trigger('toastBottomAnimation', [
  transition(':enter', [style({ transform: 'translateY(100%)' }), animate(`300ms 0ms ${cubic_bezier}`, style({ transform: 'translateY(0%)' }))]),
  transition(':leave', [animate(`300ms 0ms ${cubic_bezier}`, style({ transform: 'translateY(100%)' }))]),
]);

export const STAGGER_APPEAR_ANIMATION = trigger('staggerAppearAnimation', [
  transition('* => *', [
    query(':enter', style({ opacity: 0, height: 0, overflow: 'hidden' }), { optional: true }),

    query(
      ':enter',
      stagger('500ms', [
        animate(
          '500ms 0ms cubic-bezier(0.305, 0.005, 0.125, 1.000)',
          keyframes([style({ opacity: 0, height: 0 }), style({ opacity: 1 }), style({ opacity: 1, height: '*' })])
        ),
      ]),
      { optional: true }
    ),
  ]),
]);

export const FADE_IN_ANIMATION = trigger('fadeInAnimation', [
  transition(':enter', [style({ opacity: 0 }), animate(`150ms 0ms ease-out`, style({ opacity: 1 }))]),
  transition(':leave', [animate(`150ms 0ms ease-out`, style({ opacity: 0 }))]),
]);

export const SCALE_ANIMATION = (from = 0) =>
  trigger('scaleAnimation', [
    transition(':enter', [style({ transform: `scale(${from})` }), animate(`150ms 0ms ease-out`, style({ transform: 'scale(1)' }))]),
    transition(':leave', [animate(`150ms 0ms ease-out`, style({ transform: `scale(${from})` }))]),
  ]);
