export function inFirstButNotSecond<T>(arr1: Array<T>, arr2: Array<T>, propertyName?: string): Array<T> {
  return arr1.filter((arr1Obj) => !arr2.find((arr2Obj) => (propertyName ? arr2Obj[propertyName] === arr1Obj[propertyName] : arr1Obj === arr2Obj)));
}

export function sortArray<T>(unsorted: Array<T>, getProperty: (item: T) => string) {
  unsorted.sort((a, b) => {
    const orderA = getProperty(a).toLowerCase();
    const orderB = getProperty(b).toLowerCase();
    if (orderA < orderB) {
      return -1;
    }
    if (orderA > orderB) {
      return 1;
    }
    return 0;
  });
}

export function arrayToString(arr: Array<any> | undefined): string {
  if (arr === undefined) return '';

  arr.sort((a, b) => {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  });

  return arr.map((item) => item.toString()).join(',');
}

export enum E_RemoveFrom {
  StartOnly,
  EndOnly,
  Both,
}

/**
 * Remove repeated text from a field in an array of objects
 * @param items The items to process
 * @param field The field on each item to process
 * @param minimalMatchCount The minimum number of times a match must occur for us to keep it (-1 means all must match)
 * @param endToRemoveFrom Whether to remove text from the start, end or both
 */
// eslint-disable-next-line complexity
export function removeRepeatedWordsFromField(
  items: Array<any>,
  field: string,
  minimalMatchCount = -1,
  endToRemoveFrom: E_RemoveFrom = E_RemoveFrom.StartOnly
): void {
  class WordCount {
    constructor(public word: string, public count: number = 0) {}
  }

  function matchWords(allCriteriaWords: Map<any, Array<WordCount>>, valueWords: Array<string>) {
    for (const criteriaWords of allCriteriaWords.values()) {
      for (let i = 0; i < valueWords.length; i++) {
        if (valueWords[i] === criteriaWords[i].word) {
          criteriaWords[i].count += 1;
        } else {
          break;
        }
      }
    }
  }

  function removeLowCounts(commonWords: Map<any, Array<WordCount>>, reqdCount: number) {
    for (const item of items) {
      const wordCounts = commonWords.get(item);
      if (!wordCounts) continue;
      for (let i = 0; i < wordCounts.length; i++) {
        if (wordCounts[i].count < reqdCount) {
          wordCounts.splice(i);
          break;
        }
      }
    }
  }

  // The required count should be the number of items if -1 was passed in OR if the minimal match count is greater than the number of items (as we'd never hit the target)
  const requiredCount = minimalMatchCount === -1 || items.length < minimalMatchCount ? items.length : minimalMatchCount;

  // If the required count is 1, we'd end up removing everything here so may as well return and do nothing
  if (requiredCount === 1) return;

  const commonWordsForward = new Map<any, Array<WordCount>>();
  const commonWordsBackward = new Map<any, Array<WordCount>>();

  const processStart = endToRemoveFrom === E_RemoveFrom.StartOnly || endToRemoveFrom === E_RemoveFrom.Both;
  const processEnd = endToRemoveFrom === E_RemoveFrom.EndOnly || endToRemoveFrom === E_RemoveFrom.Both;

  // Loop through the first time and build up word counts for each field value
  for (const item of items) {
    if (processStart)
      commonWordsForward.set(
        item,
        item[field]
          .toUpperCase()
          .split(' ')
          .map((i) => new WordCount(i))
      );
    if (processEnd)
      commonWordsBackward.set(
        item,
        item[field]
          .toUpperCase()
          .split(' ')
          .reverse()
          .map((i) => new WordCount(i))
      );
  }

  // Now go through the items again and count up how many times each field has the various combinations
  for (const item of items) {
    const testWords = item[field].toUpperCase().split(' ');
    if (processStart) matchWords(commonWordsForward, testWords);
    if (processEnd) matchWords(commonWordsBackward, [...testWords].reverse());
  }

  // Now remove any words from the commonWords arrays where the count doesn't match the required count
  if (processStart) {
    for (const item of items) {
      removeLowCounts(commonWordsForward, requiredCount);
      const startPos = commonWordsForward
        .get(item)
        ?.map((i) => i.word)
        .join(' ').length;
      if (startPos && startPos > 0) item[field] = item[field].substring(startPos).trim();
    }
  }

  if (processEnd) {
    for (const item of items) {
      removeLowCounts(commonWordsBackward, requiredCount);

      const lengthFromEnd = commonWordsBackward
        .get(item)
        ?.map((i) => i.word)
        .join(' ').length;
      const midPos = lengthFromEnd && item[field].length - lengthFromEnd;
      if (midPos && midPos > 0) item[field] = item[field].substring(0, midPos).trim();
    }
  }
}
