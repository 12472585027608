import { Subscription } from 'rxjs';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { E_Event, PushUpdatesService, SiteSettingsSink } from 'src/app/shared/services/push-updates.service';
import Bugsnag from '@bugsnag/js';
import { CommonService } from 'src/app/shared/services/common.service';
import { SiteSettingsEntry } from 'src/app/data_model/site-settings';
import { SiteSettingsService } from 'src/app/shared/services/site-settings.service';
import { isDefined } from 'src/app/shared/utils/rxjs';

export function Pusher_subscribeToSiteSettings(
  pushUpdateService: PushUpdatesService,
  notificationService: NotificationService,
  commonService: CommonService,
  siteSettingsService: SiteSettingsService
): Subscription {
  return pushUpdateService.SiteSettingsSubject.pipe(isDefined()).subscribe((notification: SiteSettingsSink) => {
    const entry = new SiteSettingsEntry(notification.item);
    const site = commonService.sites.find((s) => s.id === notification.item.site_id);
    if (!site) return;

    try {
      switch (notification.event) {
        case E_Event.Updated:
          site.settings.merge(entry);
          if (entry.site_id === siteSettingsService.settings.site_id) siteSettingsService.settings.merge(entry);
          break;
        default:
          throw new Error('Unexpected notification type.');
      }
    } catch (err) {
      notificationService.reportWarning('Error processing update notification');
      Bugsnag.notify(new Error(err));
    }
  });
}
