import { Injectable } from '@angular/core';
import { JWTService } from './jwt.service';
import { SessionService } from './session.service';

@Injectable()
export class AppInitService {
  constructor(private _jwtService: JWTService, private _sessionService: SessionService) {}

  public async init(): Promise<void> {
    try {
      await this._sessionService.init();
      await this._jwtService.initSession();
    } catch (error) {
      console.log('Failed to initialize session - logging out');

      this._sessionService.logout();

      throw error;
    }
  }
}
