import { SHARED } from 'src/app/shared/shared';
import { Component, Input } from '@angular/core';
import { LoaderComponent } from '../loader/loader.component';

@Component({
  selector: 'dentr-interstitial-loader-button',
  templateUrl: './interstitial-loader-button.component.html',
  imports: [SHARED, LoaderComponent],
  standalone: true,
})
export class InterstitialLoaderButtonComponent {
  _isLoading: boolean;
  get isLoading(): boolean {
    return this._isLoading;
  }
  @Input() set isLoading(value: boolean) {
    this._isLoading = value;
    this.disabled = value;
  }

  @Input()
  public loadingText = 'Loading';

  @Input()
  public buttonClass = 'button-primary';
  public disabled = false;
}
