import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { NotificationService } from './notification.service';

export enum E_LoggedInUserPromoSettingField {
  TASK_REMINDERS = 'show_task_reminders_promo',
}

@Injectable({
  providedIn: 'root',
})
export class LoggedInUserSettingsService {
  constructor(private _httpService: HttpService, private _notificationService: NotificationService) {}

  private _handleUpdatePromoSettingError(err: any): void {
    this._notificationService.reportWarning('Cannot updated user settings', err);
  }

  public async updatePromoSetting(promo: E_LoggedInUserPromoSettingField, value: boolean): Promise<void> {
    const query = `mutation {
      updateLoggedInUserSettings(updated_item: {
        ${promo}: ${value}
      })
    }`;
    try {
      const result = await this._httpService.fetchData<any>(query);
      if (result.errors) this._handleUpdatePromoSettingError(result.errors[0]?.message);
    } catch (err) {
      this._handleUpdatePromoSettingError(err);
    }
  }
}
