import { AWS_DEV_ACCOUNT_ID, HSO_REGIONS } from "../../../shared/constants";

function env(varName: string, defaultValue?: string): string {
  const value = process.env[varName];

  if (value !== undefined && value !== "") {
    return value;
  }

  if (defaultValue !== undefined) return defaultValue;

  const errorMessage = `${varName} environment variable required but not set`;

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  if (allEnvs.IS_DEV) throw new Error(errorMessage);

  console.trace(errorMessage);

  return "";
}

/**
 * Temporary function which uses a default value if the env var is not set, but does error.
 * There are some values in .env which I don't think need to be in there (e.g. ),
 * but I don't want to break anything if I am wrong.
 *
 * ACCOUNT - set to this.account in stackUtilss
 */
function envDefaultWithError(varName: string, defaultValue: string) {
  try {
    return env(varName);
  } catch (e) {
    console.error(`Trying to use environment value without a default`, "e799537a");
    return defaultValue;
  }
}

function envBool(varName: string, defaultValue?: boolean) {
  const value = env(varName, `${defaultValue}`);
  return ["TRUE", "YES"].includes(value.toUpperCase());
}

const allEnvs = {
  get ACCOUNT() {
    //TODO - revert this back to env( if we don't see the error come through
    //return env("ACCOUNT");
    return envDefaultWithError("ACCOUNT", "770966829753");
  },
  get API_DOMAIN_NAME() {
    return env("API_DOMAIN_NAME");
  },
  get APP_ADMIN_URL() {
    return env("APP_ADMIN_URL");
  },
  get AURORA_CLUSTER_ARN() {
    return env("AURORA_CLUSTER_ARN");
  },
  get AURORA_DB_NAME() {
    return env("AURORA_DB_NAME");
  },
  get AURORA_SECRET_ARN() {
    return env("AURORA_SECRET_ARN");
  },
  get AWS_REGION() {
    return env("AWS_REGION");
  },
  get CERT_ARN() {
    return env("CERT_ARN");
  },
  get CERT_ARN_STATIC() {
    return env("CERT_ARN_STATIC");
  },
  get COLORIZE_LOGS(): boolean {
    return envBool("COLORIZE_LOGS", false);
  },

  /**
   * THIS IS DIFFERENT TO STAGE
   * represents tables in the rds / dynamo databases and s3 bucket.
   */
  get DATA_STAGE() {
    return env("DATA_STAGE");
  },

  /**
   * the id of the internal 'Dentally' practice (not a real practice)
   * making this an env var as new regions will have a different internal practice
   */
  get DENTALLY_INTERNAL_PRACTICE_ID() {
    return env("DENTALLY_INTERNAL_PRACTICE_ID");
  },
  /**
   * This was renamed from DENTALLY_redirect_uri which should have broken it if it was used anywhere
   * as env vars are case sensitive. However, it doesn't look like it is set anywhere, and so should probably be removed.
   * Just going to deprecate it for now just in case it is used anywhere, but once everything is deployed to prod and this is stabilised
   * it should be removed.
   * @deprecated 6/5/2022
   */
  get DENTALLY_REDIRECT_URI() {
    return env("DENTALLY_REDIRECT_URI", "");
  },
  get DENTALLY_OAUTH_URL() {
    return `${this.PMS_API_URL?.replace("v1", "oauth")}`;
  },
  get DENTALLY_STRIPE_SECRET_NAME() {
    return env("DENTALLY_STRIPE_SECRET_NAME");
  },
  get STAFF_API_URL() {
    return `${this.PMS_API_URL?.replace("v1", "staff")}`;
  },
  get DEPLOY_DENTALLY_API_MOCK() {
    return envBool("DEPLOY_DENTALLY_API_MOCK", false);
  },
  get DEPLOY_E2E_TESTS_RUNNER() {
    return envBool("DEPLOY_E2E_TESTS_RUNNER", false);
  },
  get DEV_ACCOUNT_ID() {
    return AWS_DEV_ACCOUNT_ID;
  },
  get GA_TRACKING_ID_PATIENT_APP() {
    return env("GA_TRACKING_ID_PATIENT_APP");
  },
  get GENDER_API_SECRET_KEY() {
    return env("GENDER_API_SECRET_KEY", "");
  },
  get GIT_COMMIT_HASH(): string {
    return env("GIT_COMMIT_HASH", "local");
  },
  get GOOGLE_API_PROJECT_ID() {
    return env("GOOGLE_API_PROJECT_ID");
  },
  get GOOGLE_RECAPTCHA_SITE_KEY() {
    return env("GOOGLE_RECAPTCHA_SITE_KEY");
  },
  get GRAPH_URL() {
    return env("GRAPH_URL");
  },
  get GW_URL() {
    return env("GW_URL", "");
  },
  get HOST_ZONE() {
    return env("HOST_ZONE");
  },
  get HSO_REGION(): string {
    return HSO_REGIONS[env("AWS_REGION")] || "UK";
  },
  get IS_CANADA() {
    return env("AWS_REGION") === "ca-central-1";
  },
  get IS_DEV_ACCOUNT() {
    return process.env.ACCOUNT === this.DEV_ACCOUNT_ID;
  },
  get IS_DEV() {
    return envBool("IS_DEV", false);
  },
  get IS_E2E() {
    return this.PMS_API_URL.includes("dentally-apis-mock");
  },
  get JWT_SECRET_NAME(): string {
    return env("JWT_SECRET_NAME", "JWT_SECRET");
  },
  get LEX_BOT_ID(): string {
    return env("LEX_BOT_ID");
  },
  get LEX_BOT_ALIAS_ID(): string {
    return env("LEX_BOT_ALIAS_ID");
  },
  get LOG_PMS_REQUESTS() {
    return envBool("LOG_PMS_REQUESTS", false);
  },
  get LOG_LEVEL() {
    return env("LOG_LEVEL", "info");
  },
  get E2E_MOCK_DATA_STAGE() {
    return env("E2E_MOCK_DATA_STAGE", "");
  },
  get E2E_TEST_MOBILE_NUMBER(): string {
    return env("E2E_TEST_MOBILE_NUMBER");
  },
  get OPTIMIZELY_KEY() {
    return env("OPTIMIZELY_KEY");
  },
  get PMS_API_URL() {
    return env("PMS_API_URL");
  },
  get PORTAL_REGION() {
    return `${this.IS_DEV_ACCOUNT ? "d" : "p"}-${this.AWS_REGION}`;
  },
  get PATIENT_APP_PUSHER_APP_ID(): string {
    return env("PATIENT_APP_PUSHER_APP_ID");
  },
  get PATIENT_APP_PUSHER_KEY(): string {
    return env("PATIENT_APP_PUSHER_KEY");
  },
  get PATIENT_APP_PUSHER_EVENT() {
    return env("PATIENT_APP_PUSHER_EVENT");
  },
  get PATIENT_APP_PUSHER_CLUSTER(): string {
    return env("PATIENT_APP_PUSHER_CLUSTER");
  },
  get PUSHER_APP_ID() {
    return env("PUSHER_APP_ID");
  },
  get PUSHER_CLUSTER() {
    return env("PUSHER_CLUSTER");
  },
  get PUSHER_EVENT() {
    return env("PUSHER_EVENT");
  },
  get PUSHER_KEY() {
    return env("PUSHER_KEY");
  },
  get REGION() {
    return env("REGION");
  },
  get REST_LEGACY_URL() {
    return env("REST_LEGACY_URL");
  },
  get SEGMENT_SOURCE_ID() {
    return env("SEGMENT_SOURCE_ID");
  },
  get SERVICE_NAME() {
    return env("SERVICE_NAME", "");
  },
  get SKIP_DEPLOY_SCRIPT() {
    return envBool("SKIP_DEPLOY_SCRIPT", false);
  },
  get SHOULD_TRACK_ANALYTICS() {
    return envBool("SHOULD_TRACK_ANALYTICS", true);
  },
  get STAGE() {
    return env("STAGE");
  },
  get STRIPE_CLIENT_APP_ID() {
    return env("STRIPE_CLIENT_APP_ID");
  },
  get STRIPE_PUBLISHABLE_KEY() {
    return env("STRIPE_PUBLISHABLE_KEY");
  },
  get STRIPE_SECRET_KEY() {
    return env("STRIPE_SECRET_KEY");
  },
  get STRIPE_WALLETLESS_CHECKOUT_CONFIGURATION() {
    return env("STRIPE_WALLETLESS_CHECKOUT_CONFIGURATION");
  },
  get TWILIO_acccount_sid() {
    return env("TWILIO_acccount_sid");
  },
  get TWILIO_auth_code() {
    return env("TWILIO_auth_code");
  },
  get TWILIO_phone_number_AU() {
    return env("TWILIO_phone_number_AU");
  },
  get TWILIO_phone_number_DEFAULT() {
    return env("TWILIO_phone_number_DEFAULT");
  },
  get TWILIO_phone_number_NZ() {
    return env("TWILIO_phone_number_NZ");
  },
  get MAILGUN_URL() {
    return env("MAILGUN_URL");
  },
  get MIXPANEL_HOST() {
    return env("MIXPANEL_HOST");
  },
  get MIXPANEL_PROJECT_TOKEN_PATIENTS() {
    return env("MIXPANEL_PROJECT_TOKEN_PATIENTS");
  },
  get MIXPANEL_PROJECT_TOKEN_USERS() {
    return env("MIXPANEL_PROJECT_TOKEN_USERS");
  },
  get VERSION() {
    return env("VERSION");
  },
};

export default allEnvs;
