import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import { arrayToString, inFirstButNotSecond } from './object-array';

export interface I_CustomInfoType {
  fieldName: string;
  renderer: (newValue: any, oldValue: any) => string | null;
}

// eslint-disable-next-line complexity
function gatherProperties<T>(updatedItem: T, originalItem?: T, includeArrays = false, customInfo: I_CustomInfoType | undefined = undefined): Array<string> {
  const updatedProps = new Array<string>();

  for (const propertyName in updatedItem) {
    if (Object.prototype.hasOwnProperty.call(updatedItem, propertyName)) {
      const propertyValue: any = updatedItem[propertyName];

      if (includeArrays && isArray(propertyValue)) {
        const originalValue = originalItem && arrayToString(originalItem[propertyName] as unknown as Array<any>);
        const updatedValue = arrayToString(propertyValue);

        if (originalValue !== updatedValue) {
          const valString = `${propertyName}: ${JSON.stringify(propertyValue)}`;
          updatedProps.push(valString);
        }
      } else if (customInfo && customInfo.fieldName === propertyName) {
        const customProp = customInfo.renderer(propertyValue, originalItem?.[propertyName]);
        if (customProp) updatedProps.push(customProp);
      } else if (!(isArray(propertyValue) || isObject(propertyValue))) {
        // Filter out non-primitive type values
        // Check if the value is different if we're supplied an item to compare against
        if (!originalItem || propertyValue !== originalItem[propertyName]) {
          const valType = typeof propertyValue;
          let valString = `${propertyName}:`;
          if (propertyValue === null) {
            valString += 'null';
          } else if (valType === 'boolean' || valType === 'number') {
            valString += `${propertyValue.toString()}`;
          } else {
            // Triple quotes to support GraphQL block strings (http://spec.graphql.org/draft/#sec-String-Value.Block-Strings)
            valString += `"""${propertyValue}"""`;
          }
          updatedProps.push(valString);
        }
      }
    }
  }

  return updatedProps;
}

function getPrefix(mutationTemplate: string): string {
  return mutationTemplate.substring(0, mutationTemplate.indexOf('(')) || 'mut';
}

export function updateProperties<T>(
  updatedItem: T,
  originalItem: T,
  mutationTemplate: string,
  mutationCmds: Array<string>,
  includeArrays = false,
  customInfo: I_CustomInfoType | undefined = undefined
): void {
  const updatedProps = gatherProperties<T>(updatedItem, originalItem, includeArrays, customInfo);
  const index = mutationCmds.length;
  if (updatedProps.length > 0) {
    const prefix = getPrefix(mutationTemplate);
    const query = `${prefix}_${index.toString()}:${mutationTemplate} updated_item:{ ${updatedProps.join(' ')} })`;
    mutationCmds.push(query);
  }
}

export function updateLinkedTable<T>(
  updatedArray: Array<T>,
  originalArray: Array<T>,
  foreignKeyProperty: string,
  createMutationTemplate: string,
  deleteMutationTemplate: string,
  mutationCmds: Array<string>,
  updateMutationTemplate?: string
) {
  // Check for added/removed items
  const addedItems = inFirstButNotSecond(updatedArray, originalArray, foreignKeyProperty);
  const removedItems = inFirstButNotSecond(originalArray, updatedArray, foreignKeyProperty);

  if (addedItems.length > 0 || removedItems.length > 0) {
    let index = mutationCmds.length;
    for (const addedItem of addedItems) {
      const prefix = getPrefix(createMutationTemplate);
      const newProps = gatherProperties<T>(addedItem);
      mutationCmds.push(`${prefix}_${index.toString()}:${createMutationTemplate} ${newProps.join(' ')} }) { ${foreignKeyProperty} }`);
      index += 1;
    }
    for (const removedItem of removedItems) {
      const prefix = getPrefix(deleteMutationTemplate);
      mutationCmds.push(`${prefix}_${index.toString()}:${deleteMutationTemplate} ${foreignKeyProperty}:"${removedItem[foreignKeyProperty]}")`);
      index += 1;
    }
  }

  // Check for updated items (if we have an update mutation template)
  if (updateMutationTemplate) {
    for (const updatedItem of updatedArray) {
      // Look in the original array for the matching item
      const originalItem = originalArray.find((orig) => orig[foreignKeyProperty] === updatedItem[foreignKeyProperty]);
      if (originalItem) {
        updateProperties<T>(updatedItem, originalItem, `${updateMutationTemplate} ${foreignKeyProperty}:"${updatedItem[foreignKeyProperty]}"`, mutationCmds);
      }
    }
  }
}
