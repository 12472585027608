import Bugsnag from '@bugsnag/js';
import { Subscription } from 'rxjs';
import { LoggedInUserSettingsEntry } from 'src/app/data_model/logged-in-user-settings';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { E_Event, LoggedInUserSettingsSink, PushUpdatesService } from 'src/app/shared/services/push-updates.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { isDefined } from 'src/app/shared/utils/rxjs';

export function Pusher_subscribeToTaskRemindersPromo(
  pushUpdateService: PushUpdatesService,
  notificationService: NotificationService,
  commonService: CommonService
): Subscription {
  return pushUpdateService.LoggedInUserSettingsSubject.pipe(isDefined()).subscribe((notification: LoggedInUserSettingsSink) => {
    try {
      const entry = new LoggedInUserSettingsEntry(notification.item);
      switch (notification.event) {
        case E_Event.Created:
        case E_Event.Updated:
          commonService.userSettings.merge(entry);
          break;

        default:
          throw new Error('Unexpected notification type.');
      }
    } catch (err) {
      notificationService.reportWarning('Error processing update notification');
      Bugsnag.notify(new Error(err));
    }
  });
}
