import { SHARED } from 'src/app/shared/shared';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from '../../../../../shared/services/common.service';
import { ModalComponent } from '../../../../../shared/components/modal/modal.component';
import { OverlayService } from 'src/app/shared/services/overlay.service';
import { DevicesComponent } from 'src/app/shared/components/devices/devices.component';
import { Enums } from 'src/enums';
import { E_LoggedInUserPromoSettingField, LoggedInUserSettingsService } from 'src/app/shared/services/logged-in-user-settings.service';
import { SiteSettingsService } from 'src/app/shared/services/site-settings.service';
import { E_PatientNotificationsSettingName } from '@shared/constants';
import { InterstitialLoaderButtonComponent } from 'src/app/shared/components/interstitial-loader-button/interstitial-loader-button.component';
import { SubSink } from 'subsink';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { PushUpdatesService } from 'src/app/shared/services/push-updates.service';
import { EnvService } from 'src/app/shared/services/env.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { LoaderComponent } from 'src/app/shared/components/loader/loader.component';
import { Pusher_subscribeToTaskRemindersPromo } from '../pusher-callbacks/task-reminders';
import { LoggedInUserSettingsEntry } from 'src/app/data_model/logged-in-user-settings';
import { Pusher_subscribeToSiteSettings } from '../../../settings/pusher-callbacks/site-settings';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroLightBulb, heroCheck } from '@ng-icons/heroicons/outline';

@Component({
  selector: 'dentr-task-reminders-activation',
  templateUrl: './task-reminders-activation.component.html',
  standalone: true,
  imports: [ModalComponent, SHARED, NgIconComponent, LoaderComponent, DevicesComponent, InterstitialLoaderButtonComponent],
  providers: [provideIcons({ heroLightBulb, heroCheck })],
})
export class TaskRemindersActivationComponent implements OnInit, OnDestroy {
  public DEVICES = Enums.DEVICES;
  public E_DEVICES_SIZE = Enums.E_DEVICES_SIZE;
  public E_DEVICES_SCALE_CLASSES = Enums.E_DEVICES_SCALE_CLASSES.IPHONE_14_PRO_SM;
  public statusText = '';
  public loading = false;
  public activated = false;
  public previewLoading = false;
  public previewURL: SafeResourceUrl;
  private _subs = new SubSink();
  constructor(
    public commonService: CommonService,
    private _overlayService: OverlayService,
    private _loggedInUserSettingsService: LoggedInUserSettingsService,
    private _siteSettingsService: SiteSettingsService,
    private _notificationService: NotificationService,
    private _pushUpdatesService: PushUpdatesService,
    private _sanitizer: DomSanitizer,
    private _envService: EnvService
  ) {}

  public ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  public ngOnInit(): void {
    this._setPreviewURL();
    this._subs.sink = Pusher_subscribeToSiteSettings(this._pushUpdatesService, this._notificationService, this.commonService, this._siteSettingsService);
    this._subs.sink = Pusher_subscribeToTaskRemindersPromo(this._pushUpdatesService, this._notificationService, this.commonService);
    this._subs.sink = LoggedInUserSettingsEntry.onMerge.subscribe(() => {
      if (this._siteWithoutTaskReminders.length !== 0) return;
      this.loading = false;
      this.statusText = '';
      this.activated = true;
    });
  }

  private _setPreviewURL(): void {
    const siteName = this.commonService.selectedSite?.name || this.commonService.sites[0].name;
    const name = this.commonService.user.first_name;
    const url = `${this._envService.env.REST_URL}/api/communications/v1/sms/preview/PATIENT_TASK_REMINDERS?name=${siteName}&brand_url=https://sign.dentr.net&patient_first_name=${name}`;

    this.previewLoading = true;

    this.previewURL = this._sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  public previewLoaded(): void {
    this.previewLoading = false;
  }

  private _updatePromoSetting(): void {
    this._loggedInUserSettingsService.updatePromoSetting(E_LoggedInUserPromoSettingField.TASK_REMINDERS, false);
  }

  public close(): void {
    this._overlayService.close();
  }

  public skip(): void {
    this._updatePromoSetting();
    this._overlayService.close();
  }

  private get _siteWithoutTaskReminders(): Array<string> {
    return this.commonService.sites.filter((site) => !site.settings.comms__task_reminders__patient_sms).map((site) => site.id);
  }

  public activateTaskReminders(): void {
    if (this._siteWithoutTaskReminders.length === 0) this.skip(); // Defensive code in case someone manually changes the setting in the DB
    this.loading = true;
    this.statusText = 'Activating';
    this._siteSettingsService.updateMultipleSiteSettings(E_PatientNotificationsSettingName.TASK_REMINDERS_SMS, true, this._siteWithoutTaskReminders, true);
  }

  public goToDentallyTemplates(): void {
    const dentally_stage = environment.production ? 'app' : 'sandbox';
    window.open(`https://${dentally_stage}.dentally.co/settings/practice/communication`, '_blank');
  }
}
