import { E_GlobalDomains, USE_NEW_DOMAIN_PRACTICE_IDS } from "./constants";

export function useNewDomain(practiceId: string, region: string): boolean {
  return USE_NEW_DOMAIN_PRACTICE_IDS.includes(practiceId) || region === "ca-central-1";
}

export function getNewDomain(domain: string, stage: string): string {
  if (domain.includes("dentr.net")) {
    return domain.replace("-patient.dentr.net", `.${E_GlobalDomains.PRODUCTION}`).replace("dentr.net", E_GlobalDomains.PRODUCTION);
  }

  if (domain.includes("dentr.io") && ["sandbox", "e2e", "test"].includes(stage)) {
    // Allow stages other than sandbox and e2e to continue using the old dentr.io domain until https://github.com/dentally/dentr/issues/5191 is completed
    return domain.replace("-patient.dentr.io", `.${E_GlobalDomains.SANDBOX}`).replace("sandbox.sandbox", "sandbox");
  }

  return domain;
}

export function getDomain(practiceId: string, domain: string, stage: string, region: string): string {
  if (!useNewDomain(practiceId, region) || !domain) return domain;

  return getNewDomain(domain, stage);
}

export function getGlobalDomain(isProd: boolean): string {
  return isProd ? E_GlobalDomains.PRODUCTION : E_GlobalDomains.SANDBOX;
}
