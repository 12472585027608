<dentr-modal>
  <div class="mb-10 flex items-center justify-between">
    <h2 class="heading-xl">Streamline reception with task reminders by up to 2.7x more forms completed before attendance*</h2>
    <div class="ml-6 flex items-center justify-end">
      <button class="button-text" *ngIf="!activated" [disabled]="loading" (click)="skip()">Skip</button>
      <button class="button-text" *ngIf="activated" [disabled]="loading" (click)="close()">Close</button>
    </div>
  </div>
  <div class="mb-10 flex items-center justify-between">
    <div class="mr-10 bg-[url('assets/skewed_circle.svg')] bg-contain bg-center bg-no-repeat px-14">
      <div class="{{ E_DEVICES_SCALE_CLASSES }}">
        <dentr-devices [device]="DEVICES.IPHONE_14_PRO" [size]="E_DEVICES_SIZE.SM">
          <div class="relative">
            <div class="absolute h-full w-full">
              <div *ngIf="previewLoading" class="flex h-full items-center justify-center">
                <dentr-loader class="mr-1.5 block h-10 w-10 text-gray-400"></dentr-loader>
              </div>
            </div>

            <iframe *ngIf="previewURL" [src]="previewURL" class="h-full w-full" [ngClass]="{ 'opacity-50': previewLoading }" (load)="previewLoaded()"></iframe>
          </div>
        </dentr-devices>
      </div>
    </div>
    <div class="space-y-6">
      <div>
        <h3 class="heading-lg mb-1">Efficient automation</h3>
        <p>Only the patients with forms to complete receive a single SMS, the day before their appointment, at your usual rate.</p>
      </div>
      <div>
        <h3 class="heading-lg mb-1">Easy and secure</h3>
        <p>Every patient gets a secure unique link that allows them to complete forms without logging in.</p>
      </div>
      <div>
        <h3 class="heading-lg mb-1">Simple to set up, instant time saving</h3>
        <p>Setting up task reminders is as easy as clicking a button.</p>
      </div>
    </div>
  </div>
  <div class="bg-grape-100 rounded-lg p-4">
    <div *ngIf="!commonService.hasPermission_PatientNotifications" data-unit-test-task-reminder-promo-restricted-msg>
      <p class="text-grape-700 text-center">
        <span>Ready to save time? Please ask an administrator to turn on task reminders.</span>
      </p>
    </div>
    <div *ngIf="commonService.hasPermission_PatientNotifications" data-unit-test-task-reminder-promo-activation>
      <h4 class="flex items-center justify-between">
        <p *ngIf="!activated" class="text-grape-700 heading-md">Ready to save time?</p>
        <p *ngIf="activated">Thanks for activating, we'll send out your first batch tomorrow.</p>
        <dentr-interstitial-loader-button *ngIf="!activated" [isLoading]="loading" [loadingText]="statusText" (click)="activateTaskReminders()">
          Activate task reminders
        </dentr-interstitial-loader-button>
        <span *ngIf="activated" class="button-tertiary button-small pointer-events-none flex items-center justify-between">
          <ng-icon name="heroCheck" size="18"></ng-icon>
          <span class="ml-2">Task reminders active</span>
        </span>
      </h4>
      <div *ngIf="activated" class="mt-4 rounded-md bg-white p-3">
        <div class="inline-flex items-center justify-center">
          <ng-icon name="heroLightBulb" size="24" class="text-grape-700 mr-3"></ng-icon>
          <p>
            TIP: Remove the Portal link from your
            <span class="link underline" (click)="goToDentallyTemplates()" role="link">appointment reminder template</span> to save patients logging in to
            complete forms.
          </p>
        </div>
      </div>
    </div>
  </div>
  <p class="mt-4 text-gray-400">*based on a comparison of data from over 300 dental practices June vs September 2023</p>
</dentr-modal>
