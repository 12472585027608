import { Injectable } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import { SiteSettingsEntry } from 'src/app/data_model/site-settings';
import { updateProperties } from '../utils/graph-save-functions';
import { HttpService } from './http.service';
import { NotificationService } from './notification.service';
import { CommonService } from './common.service';
import { Subject } from 'rxjs';
import { E_ResourceType } from '@backend/common/schema';
import { OverlayService } from './overlay.service';
import Bugsnag from '@bugsnag/js';

const COMMON_QUERY = `
  id
  settings {
    forms__nhs_pr
    forms__medical_history
    forms__treamtent_plan_estimates
    payments__invoice_payments
    payments__balance_payments

    comms__booking_new_patient__practice_email
    comms__booking_existing_patient__practice_email
    comms__booking_cancellation__practice_email
    comms__booking_patient_comment__practice_email
    comms__booking_confirmation__patient_email
    comms__booking_confirmation__patient_sms
    comms__booking_cancellation__patient_email
    comms__booking_cancellation__patient_sms
    comms__task_reminders__patient_email
    comms__task_reminders__patient_sms
  }
`;

const QUERY = (is_multi_site: boolean, site_id: string) => {
  if (is_multi_site) {
    return `
      {
        practice {
          sites {
            items {
              ${COMMON_QUERY}
            }
          }
        }
      }
    `;
  } else {
    return `
      {
        practice {
          site(site_id: "${site_id}") {
            ${COMMON_QUERY}
          }
        }
      }
    `;
  }
};

const resourceToMutation = new Map<E_ResourceType, string>();
resourceToMutation.set(E_ResourceType.SiteSettings_OnlineSigning, 'updateSiteOnlineSigningSettings');
resourceToMutation.set(E_ResourceType.SiteSettings_PatientNotifications, 'updateSitePatientNotificationSettings');
resourceToMutation.set(E_ResourceType.SiteSettings_PaymentTypes, 'updateSitePaymentTypeSettings');
resourceToMutation.set(E_ResourceType.SiteSettings_PracticeNotifications, 'updateSitePracticeNotificationSetings');
resourceToMutation.set(E_ResourceType.SiteSettings_TaskReminderNotifications, 'updateSiteTaskReminderNotificationSettings');

@Injectable({
  providedIn: 'root',
})
export class SiteSettingsService {
  private _settings = new SiteSettingsEntry();
  public onSettingsUpdated = new Subject<SiteSettingsEntry>();
  constructor(
    private _httpService: HttpService,
    private _notificationService: NotificationService,
    private _commonService: CommonService,
    private _overlayService: OverlayService
  ) {}

  // #region mutations/queries
  public async getSiteSettings(): Promise<void> {
    const is_multi_site = this._commonService.isMultiSite && this._commonService.isAllSites;

    if (!this._commonService.selectedSiteId) {
      Bugsnag.notify('Cannot get site settings without a selected siteId');
      throw new Error('Cannot get site settings without a selected siteId');
    }

    const result = await this._httpService.fetchData<any>(QUERY(is_multi_site, this._commonService.selectedSiteId));

    if (result) {
      const sites = is_multi_site ? result.practice.sites.items : [result.practice.site];
      for (const site of sites) {
        const found_site = this._commonService.sites.find((s) => s.id === site.id);
        if (found_site) merge(found_site.settings, site.settings);
      }
      if (!is_multi_site) {
        if (!this._commonService.selectedSite) {
          Bugsnag.notify('Cannot get (non-multisite) site settings without a selected site');
          throw new Error('Cannot get (non-multisite) site settings without a selected site');
        }
        this.settings = cloneDeep(this._commonService.selectedSite.settings);
        this.settings.site_id = this._commonService.selectedSite.id;
      } else {
        this.settings = new SiteSettingsEntry();
      }
      this.onSettingsUpdated.next(this.settings);
    }
  }

  // eslint-disable-next-line complexity
  public async updateMultipleSiteSettings(field: string, value: boolean, site_ids: Array<string>, update_user_setting = false): Promise<void> {
    for (const site of this._commonService.sites) {
      if (site_ids.includes(site.id)) {
        // Remove any site ids where the value hasn't changed
        const currentSettings = site.settings;
        if (currentSettings[field] === value) site_ids.splice(site_ids.indexOf(site.id), 1);
      }
    }

    if (site_ids.length === 0) return;

    const resourceType = SiteSettingsEntry.fieldToResourceType(field);
    if (resourceType == null) throw new Error(`Failed to determine a SiteSetting resource type for the "${field}" field (5d87b97c)`);

    const update_user_setting_str = update_user_setting ? `, update_user_setting: ${update_user_setting}` : '';
    const query = `mutation {
        ${resourceToMutation.get(resourceType)}(site_ids: [${site_ids
      .map((id) => `"${id}"`)
      .join(',')}], updated_item: { ${field}: ${value} }${update_user_setting_str})
      }
    `;

    try {
      const result: any = await this._httpService.fetchData(query);
      if (result.errors) this._notificationService.reportWarning('Cannot save site settings', result.errors[0]?.message);
    } catch (err) {
      // Set the settings back to what they were before the update
      for (const site_id of site_ids) {
        const site = this._commonService.sites.find((s) => s.id === site_id);
        if (site) site.settings[field] = !value;
      }
      this._overlayService.close();
      this._notificationService.reportWarning('Cannot save site settings', err.message);
      throw err;
    }
  }

  public async updateSiteSettings(subResourceType: E_ResourceType): Promise<void> {
    if (!this._commonService.selectedSite) {
      Bugsnag.notify('Cannot updateSiteSettings without a selected site');
      throw new Error('Cannot updateSiteSettings without a selected site');
    }
    const settings = cloneDeep(this.settings);
    const mutationCmds = new Array<string>();

    const fieldsToKeep = SiteSettingsEntry.resourceTypeToFields(subResourceType);
    for (const field of Object.keys(settings)) {
      if (!fieldsToKeep.includes(field)) delete settings[field];
    }

    updateProperties<SiteSettingsEntry>(
      settings,
      this._commonService.selectedSite.settings,
      `${resourceToMutation.get(subResourceType)}(site_ids: ["${this._commonService.selectedSiteId}"]`,
      mutationCmds,
      false
    );

    if (mutationCmds.length > 0) {
      const query = `mutation {\n${mutationCmds.join('\n')}\n}`;
      try {
        const result: any = await this._httpService.fetchData(query);
        if (result.errors) this._notificationService.reportWarning('Cannot save site settings', result.errors[0]?.message);
      } catch (err) {
        // Set the settings back to what they were before the update
        for (const field of Object.keys(settings)) {
          this.settings[field] = this._commonService.selectedSite.settings[field];
        }
        this._notificationService.reportWarning('Cannot save site settings', err.message);
        throw err;
      }
    }
    if (this._commonService.isAllSites) this.settings = new SiteSettingsEntry();
  }

  // #endregion

  // #region getters/setters
  public get settings(): SiteSettingsEntry {
    return this._settings;
  }

  public set settings(value: SiteSettingsEntry) {
    this._settings = value;
  }
  // #endregion
}
